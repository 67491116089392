import Toast from './toast';
let map;
let markers = [];

var StoresMap = {
  init: function() {
    StoresMap.setMapScript();
    StoresMap.setSubmitEventListenerAndSearch();
  },

  setMapScript: function() {
    // document内に'body#stores-map'の要素がある場合のみ、googlemapのjsを読み込む
    // 読み込まれるとinitMap関数が実行される
    if ($('body#stores-map').length > 0) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${gon.gmak}&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
    } else {
      // mapを表示しないページは以降の処理をしない
      return;
    }
    // initMap関数はdocument上で呼び出されるので、initMapはdocument上で呼び出せるようにする必要がある
    // webpackerではモジュールとして名前空間に閉じられてしまう
    // グローバルな関数として定義することで、callbackで呼び出されても名前解決できるようにしている
    window.initMap = function () {
      // gonのデータを元にmapオブジェクトを生成
      const center = { lat: gon.map.center.lat, lng: gon.map.center.lng };
      const options = {
        zoom: gon.map.zoom,
        scaleControl: true,
        center: center,
        gestureHandling: "greedy",
        disableDefaultUI: true
      };
      map = new google.maps.Map(document.getElementById('map'), options);

      // 店舗データをajaxで取得し、店舗をセットする
      var stores;
      $.ajax({
        url: gon.search_path,
      }).done(function(data) {
        stores = data.stores;
        StoresMap.setStoresMarker(stores);
        StoresMap.setCurrentPosition();
      }).fail(StoresMap.failedAjax);
    }
  },

  // 店舗のマーカーをセット
  setStoresMarker: function(stores) {
    $.each(stores, function(_, store){
      const position = {
        lat: parseFloat(store.address.latitude),
        lng: parseFloat(store.address.longitude)
      };
      const icon = {
        url: store.genre_icon_path,
        size: new google.maps.Size(60, 50)
      };
      const marker = new google.maps.Marker({
        position: position,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: icon,
        storeId: store.id // 独自のプロパティを追加
      });

      // markerをクリックした時にモーダルを表示する処理
      marker.addListener("click", StoresMap.displayModal);
      markers.push(marker)
    })
  },

  // 現在地のマーカーをセット
  setCurrentPosition: function() {
    // TODO: 位置情報をサポートしていない端末に対してメッセージを表示するか
    if(!navigator.geolocation) {
      return
    }

    navigator.geolocation.getCurrentPosition(initialPosition => {
      var center = {lat: initialPosition.coords.latitude, lng: initialPosition.coords.longitude}
      map.setCenter(center)
    }, () => {
      Toast.setMessage(`位置情報が取得できませんでした<br>現在地を${gon.map.center.symbol}としています`)
      Toast.show();
    });

    const currentPositionMarker = new google.maps.Marker({
      map: map,
      icon: {
        url: gon.current_position_icon,
      }
    });

    navigator.geolocation.watchPosition(currentPosition => {
      const position = {
        lat: currentPosition.coords.latitude,
        lng: currentPosition.coords.longitude
      };
      // 位置情報が更新される度に緯度経度をセットする
      currentPositionMarker.setPosition(position);
    });
  },

  displayModal: function() {
    // モーダルをajaxで取得する
    $.ajax({
      url: `${gon.store_info_base_path}/${this.storeId}/info`
    }).done(function() {
      showModal();
    }).fail(StoresMap.failedAjax);
  },

  failedAjax: function() {
    alert('店舗情報の取得に失敗しました')
  },

  setSubmitEventListenerAndSearch: function() {
    $('.search_keywords, .filter-genres_map_form').on('submit', function(e) {
      // ページ遷移させないようにする
      e.preventDefault();
      StoresMap.searchStoresByKeywordsAndGenres();
      $('.narrowing-unshown').prop('checked', false);
      // 検索ボタンを押したらフォーカスを外す
      document.activeElement.blur();
      // submit を何度も押せるようにする
      return false
    });
  },

  searchStoresByKeywordsAndGenres: function() {
    var keywords = $('input').data('target', 'keywords').val()
    var genre_ids = [];
    $('[name="genre[id][]"]:checked').each(function() {
      genre_ids.push($(this).val());
    });
    var stores;
    var genres;
    $.ajax({
      url: gon.search_path,
      data: {
        keywords: keywords,
        genre_ids: genre_ids,
      },
      dataType: 'json',
    }).done(function(data) {
      stores = data.stores;
      genres = data.genres;
      // マーカーを全て削除
      StoresMap.deleteStoresMarkers()
      StoresMap.setStoresMarker(stores)
      StoresMap.operateFilterGenresBar(genres)
    }).fail(StoresMap.failedAjax);
  },

  // https://developers.google.com/maps/documentation/javascript/examples/marker-remove#maps_marker_remove-javascript
  // Sets the map on all markers in the array.
  setMapOnAll: function(map) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  },

  // Removes the markers from the map, but keeps them in the array.
  hideMarkers: function() {
    StoresMap.setMapOnAll(null);
  },

  deleteStoresMarkers: function() {
    StoresMap.hideMarkers();
    markers = [];
  },

  operateFilterGenresBar: function(genres) {
    if (genres?.length) {
      StoresMap.setFilterGenresBar(genres);
    } else {
      StoresMap.deleteFilterGenresBar();
    }
  },

  /**
   * 絞り込んだジャンルをリスト表示するDOMの生成、更新をする
   */
  setFilterGenresBar: function(genres) {
    var narrowingListWrapper = $('.narrowing-listwrapper')
    StoresMap.deleteFilterGenresBar();
    // ジャンルのリンク要素を配置するリストを作る
    if (!$('ul.narrowing-list')?.length) {
      narrowingListWrapper.append($('<ul/>').addClass('narrowing-list'));
    }
    var narrowingList = $('ul.narrowing-list');
    $.each(genres, function(_, genre){
      // 重複した要素を登録しないようにする
      if (narrowingList.find(`:contains('${genre.name}')`)?.length) {
        return true;
      }
      // addClass 後には append が使えないので, 初期化時にクラスをつける
      var narrowingLink = $('<a/>').addClass('narrowing-list__link').text(genre.name);
      var narrowingItem = $('<li/>', {class: 'narrowing-list__item'}).append(narrowingLink);;
      narrowingList.append(narrowingItem);

      // 親要素ベースでジャンルのリンク要素ごとにイベントを登録する
      narrowingListWrapper.on('click', `.narrowing-list__link:contains('${genre.name}')`, {genreId: genre.id} ,StoresMap.deleteFilterGenreItem);
    });
  },

  deleteFilterGenresBar: function() {
    $('ul.narrowing-list').remove();
  },

  deleteFilterGenreItem: function(e) {
    $(`#genre_id_${e.data.genreId}`).prop('checked', false);
    $(this).parent().remove();
    StoresMap.searchStoresByKeywordsAndGenres();
  }
}

$(function() {
  StoresMap.init();
});
