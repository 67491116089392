import Toast from './toast.js'
//スムースクロール
// $(function(){
// 	$('a[href^="#"]').click(function(){
// 		var speed = 500;
// 		var href= $(this).attr("href");
// 		var target = $(href == "#" || href == "" ? 'html' : href);
// 		var position = target.offset().top;
// 		$("html, body").animate({scrollTop:position}, speed, "swing");
// 		return false;
// 	});
// });

$(function(){
	$('#menu-btn').click(function(){
		$('#gNav').fadeIn();
	});
	$('#close-btn').click(function(){
		$('#gNav').fadeOut();
	});
	$('.p-trigger').on('click',function(){
		$('.popup').addClass('show');
	});
	$('.p-close').on('click',function(){
		$('.popup').removeClass('show');
	});
});

$(function(){
	$("#nav-open").on("click",function(){
		$("header").toggleClass("open");
	});
});
const genreCheckboxes = document.getElementsByName("genre[id][]")

// グローバルな関数として定義して、callbackで呼び出せるようにする
window.uncheckAll = function () {
  for(var i = 0; i < genreCheckboxes.length; i++) {
    genreCheckboxes[i].checked = false
  }
}

$(function(){
	$('#narrowing-open').on('click',function(){
		$('footer').addClass('front');
	});
	$('.narrowing-unshown').on('click',function(){
		$('footer').removeClass('front');
	});

	$('.p-trigger').on('click',function(){
		$('main').addClass('overflow-none');
		$('footer').addClass('back');
	});
	$('.p-close').on('click',function(){
		$('main').removeClass('overflow-none');
		$('footer').removeClass('back');
	});
});

// モーダルの開閉をグローバルで定義しておく
window.showModal = function() {
	$('.modal-wrapper').addClass('active');
}
window.removeModal = function() {
	$('.modal-wrapper').removeClass('active');
}
// ローディング処理をproxyで呼び出せるようにしておく
window.loadingHandler = {
	apply: async function(target, thisArg, argumentsList) {
		$('#loading').fadeIn('fast');
		await target(...argumentsList)
		$('#loading').fadeOut('fast');
		Toast.show();
		return
	}
}

// iOSのSafariなどの画面幅変更にJSで追従する
/*-- ここから --*/
const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);
// 初期化
setFillHeight();
/*-- ここまで --*/

// イベントカラーの設定
const setThemeColor = () => {
  const themeColor = gon.theme_color
	document.documentElement.style.setProperty('--main-color', `rgba(${themeColor}, 1)`);
	document.documentElement.style.setProperty('--sub-color1', `rgba(${themeColor}, 0.7)`);
	document.documentElement.style.setProperty('--sub-color2', `rgba(${themeColor}, 0.3)`);
	document.documentElement.style.setProperty('--sub-color3', `rgba(${themeColor}, 0.2)`);
	document.documentElement.style.setProperty('--sub-color4', `rgba(${themeColor}, 0.05)`);
}
setThemeColor();
