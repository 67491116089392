var LocationUtil = {
  getCurrentPositionPromise(options) {
    return new Promise((resolve, reject) => {
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      } else {
        reject();
      }
    })
  },

  getCurrentPosition: async function() {
    var currentPosition = {};
    let position = await LocationUtil.getCurrentPositionPromise()
    currentPosition = { lat: position.coords.latitude, lng: position.coords.longitude }
    return currentPosition;
  },

  // 緯度経度から2点間の距離を計算する
  // https://developers-jp.googleblog.com/2019/12/how-calculate-distances-map-maps-javascript-api.html
  calcDistance: function(lat1, lng1, lat2, lng2) {
    var R = 3958.8; // Radius of the Earth in miles

    var rlat1 = lat1 * (Math.PI / 180);
     // Convert degrees to radians
    var rlat2 = lat2 * (Math.PI / 180);
     // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (lng2 - lng1)
                * (Math.PI / 180); // Radian difference (longitudes)

    var d = 2 * R
              * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2)
              + Math.cos(rlat1) * Math.cos(rlat2)
              * Math.sin(difflon / 2) * Math.sin(difflon / 2)));

    // マイルをキロメートルに変換
    return d / 0.62137;
  },
}

export default LocationUtil;
