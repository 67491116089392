$(function(){
  $(function(){
    // TODO: マップと店舗一覧画面のみ使うため、呼び出しを制御する
    // MEMO: 初期アクセス時、未読の通知がないかを取得する
    getNotification()
    // MEMO: 3分(180000ミリ秒)ごとに未読の通知がないかを取得する
    setInterval(getNotification, 180000);
  });

  function getNotification(){

    $.ajax({
      url: gon.unread_notifications_path,
      dataType: 'json',
      type: "GET",
    }).done(function(data) {
      if(data.status === true){
        $('.notification-badge').removeClass('d-none');
      }else{
        $('.notification-badge').addClass('d-none');
      }
    });
  }
});
