import LocationUtil from './location_util';
import Toast from './toast';

var StoresSort = {
  defaultPosition: gon.map.center,

  init: function() {
    StoresSort.triggerSortEvent();
    StoresSort.addSortEvent();
  },

  triggerSortEvent: function() {
    $(window).on('load', function (){
      $('#near').trigger('click');
    });
  },

  addSortEvent: function() {
    $('#near').on('click', async () => {
      // proxyを使うことで関数の前後にローディングの処理を実行する
      await new Proxy(StoresSort.sortStoreList, loadingHandler)()
    })
  },

  sortStoreList: async function() {
    var storeList = $('#near_content li.store');
    var center = {}
    try {
      center = await LocationUtil.getCurrentPosition();
    } catch {
      center = StoresSort.defaultPosition;
      Toast.setMessage(`位置情報が取得できませんでした<br>現在地を${gon.map.center.symbol}としています`)
    }
    StoresSort.sortByDistance(storeList, center);
    storeList.each((index, store) => {
      store.style.order = index;
    })
  },

  // params
  //   storeList: li要素のarray
  //   center: Object { lat: float, lng: float }
  // storeListが破壊的にソートされる
  sortByDistance: function(storeList, center) {
    // 店舗データを緯度経度でソートする
    storeList.sort(function(a, b) {
      var aDistance = LocationUtil.calcDistance(a.dataset.latitude, a.dataset.longitude, center.lat, center.lng);
      var bDistance = LocationUtil.calcDistance(b.dataset.latitude, b.dataset.longitude, center.lat, center.lng);

      if (aDistance < bDistance) {
        return -1;
      } else if (aDistance > bDistance) {
        return 1;
      } else {
        return 0;
      }
    });
  },
}

$(function() {
  StoresSort.init();
});
