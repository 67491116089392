// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

Rails.start()

// entrypoint の設定
import "user/js/common"
import 'user/js/stores_map'
import "user/css/application.scss"
import "user/js/stores_sort"
import "user/js/toast"
import "user/js/notification_badge"

// Railsテンプレート側から画像を app/javascript/images 配下の画像を読み込む設定
const images = require.context("../images", true, /\.(jpeg|jpg|png|gif|svg|ico)$/)
const imagePath = name => images(name, true)
