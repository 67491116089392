var Toast = {
  init: function() {
    Toast.show();
  },

  show: function() {
    if ($('#toast-message').length == 0 || $('#toast-message').html().trim() == '') {
      return
    }
    $('#toast').fadeIn('fast', function() {
      $(this).delay(3000).fadeOut('fast', () => {
        // 消えた後にtoast内を空にする
        $('#toast-message').html('');
      });
    });
  },

  setMessage: function(message) {
    $('#toast-message').html(message);
  }
}

$(function() {
  Toast.init();
});

export default Toast;
